import axios from "axios";
import store from "@/store";
import {i18n} from '@/plugins/i18n.js'

export default [
    {
        path: '/catalog',
        component: () => import('@/components/layouts/CatalogLayout'),
        meta: {
            accessRole: 'SuperAdmin',
        },
        children: [
            {
                path: '',
                name: 'CatalogIndex',
                component: () => import('../../pages/catalog/CatalogIndex'),
                meta: {
                    accessRole: 'SuperAdmin',
                }
            },
            {
                path: 'distributors',
                name: 'CatalogDistributors',
                component: () => import('@/pages/catalog/Distributors'),
                meta: {
                    title: i18n.t('Distributors | EKK'),
                    accessRole: 'SuperAdmin',
                },
            },
            {
                path: 'cinemas',
                name: 'CatalogCinemas',
                component: () => import('@/pages/catalog/Cinemas'),
                meta: {
                    title: i18n.t('Cinemas | EKK'),
                    accessRole: 'SuperAdmin',
                },
            },
            {
                path: 'companies',
                name: 'Companies',
                meta: {
                    title: i18n.t('Companies | EKK'),
                    notAuth: true,
                    accessRole: 'SuperAdmin',
                },
                component: () => import('../../pages/Companies')
            },
            {
                path: 'network',
                name: 'Network',
                meta: {
                    title: i18n.t('Network | EKK'),
                    accessRole: 'SuperAdmin',
                },
                component: () => import('../../pages/Network')
            },
            {
                path: 'films',
                name: 'Films',
                meta: {
                    title: i18n.t('Movies | EKK'),
                    accessRole: 'SuperAdmin',
                },
                component: () => import('../../pages/Films')
            },
        ]
    }
]
