import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import store from './store'
import ApiService from "./services/ApiService";
import helper from "./helpers/helper"
import userPermission from "./helpers/userPermission"
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'material-icons/iconfont/material-icons.css';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"
import 'vue2-datepicker/index.css';

import {i18n} from './plugins/i18n.js'

import VueAxios from 'vue-axios'

import vSelect from "vue-select";
import Paginate from 'vuejs-paginate'
import VueLazyload from 'vue-lazyload'

//base style
import '@/assets/scss/app.scss'
import createToast from "@/helpers/createTost"

/* Vue.mixin({
    methods: {
        userCan(permission) {
            return this.$store.getters.can(permission);
        }
    }
}); */

Vue.use(VueLazyload, {
    preLoad: 1,
    attempt: 1,
    lazyComponent: true,
    observer: true,
    observerOptions: {
      rootMargin: '0px',
      threshold: 0.1
    }
});

Vue.use(VueAxios, axios)
Vue.use(VueTelInput, {
  defaultCountry: store?.getters?.getAppLocale || 'UA',
});
Vue.prototype.ApiService = ApiService


const moment = require('moment')
require('moment/locale/uk')
Vue.prototype.moment = moment

Vue.component('paginate', Paginate)
Vue.component("v-select", vSelect);
Vue.component('multiselect', Multiselect)

//window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
window.bootstrap = require('bootstrap');

Vue.mixin({
    data () {
        return{
            api: ApiService,
            helper: helper,
            role: userPermission,
        }
    }
})

Vue.prototype.toast = {
    success(title, message = null, icon = null) {
        createToast('success', title, message, icon)
    },
    info(title, message = null, icon = null) {
        createToast('info', title, message, icon)
    },
    error(title, message = null, icon = null) {
        createToast('error', title, message, icon)
    }
}

Vue.mixin({
    data() {
        return {
            api: ApiService,
        }
    }
});

Vue.config.productionTip = false

let vueConfigs = {
    router,
    store,
    i18n,
    render: h => h(App),
    created() {
        this.$store.dispatch('setLocale', this.$store.getters['authLanguage']);
    }
}
if (process.env.NODE_ENV === 'development') {
    ApiService.authentication.getIdentity().then(resp => store.dispatch('authSet', resp)).finally(() => {
        new Vue(vueConfigs).$mount('#app')
    });
}else{
    if(Object.keys(window._sharedData.user).length) {
        store.dispatch('authSet', window._sharedData.user);
        delete window._sharedData.user;
    }
    new Vue(vueConfigs).$mount('#app')
}
