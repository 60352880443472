import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth';
import common from './modules/common';
import analytics from "./modules/analytics";
import dynamics from "./modules/dynamics";
import notification from "./modules/notification"

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        common,
        analytics,
        dynamics,
        notification
    },
})
