import apiClient from "./ApiClient";
import handleApiError from "./handleApiError";

export default {
    customRequest(method, action, formData, headers) {
        return apiClient({
          method: method,
          url: action,
          data: formData,
          headers: headers
        }).then(resp => resp).catch(handleApiError);
    },
}
