import Vue from 'vue'

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
Vue.prototype.moneyFormat = function (number) {
    return (new Intl.NumberFormat(['uk'], {
        style: 'currency',
        currency: 'UAH',
        minimumFractionDigits: 2
    })).format(parseFloat(number).toFixed(2))
}
export default {
    getDate(date){
        return Vue.prototype.moment(date).format('YYYY-MM-DD')
    },
    getCurrentWeek(date){
        return Vue.prototype.moment(date).startOf('isoWeek').format('YYYY-MM-DD')
    },
    getCurrentMonth(date){
        return Vue.prototype.moment(date).startOf('month').format('YYYY-MM-DD')
    },
    getStartPreviousWeek(date){
        return Vue.prototype.moment(date).startOf('week').subtract(1, 'd').startOf('week').format('YYYY-MM-DD')
    },
    getEndPreviousWeek(date){
        return Vue.prototype.moment(date).startOf('isoWeek').subtract(1, 'd').format('YYYY-MM-DD')
    },
    getStartPreviousMonth(date){
        return Vue.prototype.moment(date).startOf('month').subtract(1, 'd').startOf('month').format('YYYY-MM-DD')
    },
    getEndPreviousMonth(date){
        return Vue.prototype.moment(date).startOf('month').subtract(1, 'd').format('YYYY-MM-DD')
    },
    getYesterdayDate(date){
        return Vue.prototype.moment(date).subtract(1, 'd').format('YYYY-MM-DD')
    },
    getTime(time){
        return Vue.prototype.moment(time).format("HH:mm:ss")
    },
    getStartNextMonth(date){
        return Vue.prototype.moment(date).add(1, 'months').startOf('month')
    },
    getFinishNextMonth(date){
        return Vue.prototype.moment(date).add(2, 'months').startOf('month').subtract(1, 'd')
    },
}
