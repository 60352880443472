import UserService from './UserService'
import AuthService from "./AuthService";
import CinemaService from "./CinemaService";
import NetworkService from "./NetworkService";
import CompaniesService from "./CompaniesService";
import CommonService from "./CommonService";
import DistributorService from './DistributorService'
import CompanyService from './CompanyService'
import CityService from './CityService'
import TicketsService from './TicketsService'
import StatisticsService from "./StatisticsService";
import SessionsService from "./SessionsService"
import ExportService from './ExportService'
import NotificationsService from "./NotificationsService";
import LogsService from "./LogsService"

export default {
    common: CommonService,
    user: UserService,
    city: CityService,
    company: CompanyService,
    authentication: AuthService,
    cinema: CinemaService,
    network: NetworkService,
    companies: CompaniesService,
    distributor: DistributorService,
    statistics: StatisticsService,
    ticket: TicketsService,
    session: SessionsService,
    export: ExportService,
    notifications: NotificationsService,
    logs: LogsService,
}
