import apiClient from "./ApiClient";
import handleApiError from "./handleApiError";

export default {
    getCompanies(params) {
        return apiClient.get(`/api/v1/companies`, {params}).then(resp => resp).catch(handleApiError);
    },
    getCompantId(id){
        return apiClient.get(`/api/v1/companies/`+id).then(resp => resp).catch(handleApiError);
    }
}
