import apiClient from "./ApiClient";
import handleApiError from "./handleApiError";

export default {
    getNetworks(params) {
        return apiClient.get(`/api/v1/networks`, {params}).then(resp => resp).catch(handleApiError);
    },
    sentNewNetwork(form){
        return apiClient.post(`/api/v1/networks`, form).then(resp => resp).catch(handleApiError);
    },
    updateNetwork(id, data){
        return apiClient.put('/api/v1/networks/'+id, data).then(resp => resp).catch(handleApiError);
    },
    getNetworkId(id){
        return apiClient.get('/api/v1/networks/'+id).then(resp => resp).catch(handleApiError);
    }
}
