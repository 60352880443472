import axios from "axios";
import store from "@/store";
import {i18n} from '@/plugins/i18n.js'

const isLoggedIn = () => true;

export default [
    {
        path: '/admin',
        component: () => import('@/components/layouts/AdminLayout'),
        children: [
            {
                path: '',
                name: 'AdminIndex',
                component: () => import('@/pages/admin/AdminIndex'),
            },
            {
                path: 'users',
                name: 'AdminUsers',
                component: () => import('@/pages/admin/Users'),
                meta: {
                    title: i18n.t('Users | EKK'),
                    accessRole: 'SuperAdmin',
                },
            },
        ]
    }
]
