import apiClient from "./ApiClient";
import handleApiError from "./handleApiError";

export default {
    getCompaniesList(params) {
        return apiClient.get('/api/v1/companies', {params}).then(resp => resp)
            .catch(handleApiError);
    },
    sentNewCompanies(form) {
        return apiClient.post('/api/v1/companies', form).then(resp => resp)
            .catch(handleApiError);
    },
    updateCompany(id, form) {
        return apiClient.put('/api/v1/companies/'+id, form, ).then(resp => resp)
            .catch(handleApiError);
    },
    getCompanies() {
        return apiClient.get(`/api/v1/companies`).then(resp => resp).catch(handleApiError);
    },

}
