import {i18n} from '@/plugins/i18n.js'


export default [
    {
        path: '/reports',
        name: 'Reports',
        meta: {
            title: i18n.t('Reports'),
        },
        component: () => import('../../pages/Reports'),
    }
]

