import apiClient from "./ApiClient";
import handleApiError from "./handleApiError";

export default {
    getCinemas(params) {
        return apiClient.get(`/api/v1/cinemas`, {params}).then(resp => resp).catch(handleApiError);
    },
    deleteCinema(id){
        return apiClient.delete(`/api/v1/cinemas/`+id).then(resp => resp).catch(handleApiError);
    },
    getCinemasHalls(params) {
        return apiClient.get(`/api/v1/cinema-halls`, {params}).then(resp => resp).catch(handleApiError);
    },
    deleteCinemasHall(id) {
        return apiClient.delete(`/api/v1/cinema-halls/${id}`).then(resp => resp).catch(handleApiError);
    },
    getFilmsList(params) {
        return apiClient.get('/api/v1/movies', {params}).then(resp => resp).catch(handleApiError);
    },
    sentNewFilm(form) {
        return apiClient.post('/api/v1/movies', form).then(resp => resp).catch(handleApiError);
    },
    updateFilm(id, data) {
        return apiClient.put('/api/v1/movies/'+id, data).then(resp => resp).catch(handleApiError);
    },
    getFilmsId(id) {
        return apiClient.get(`/api/v1/movies/`+id).then(resp => resp).catch(handleApiError);
    },
    getCinemaId(id) {
        return apiClient.get(`/api/v1/cinemas/`+id).then(resp => resp).catch(handleApiError);
    },
    cinemaWithoutPagination(){
        return apiClient.get('/api/v1/cinemas', {params: {no_pagination: 1}}).then(resp => resp.data).catch(handleApiError);
    },
    getCinemaHalls(){
        return apiClient.get(`/api/v1/cinema-halls`).then(resp => resp).catch(handleApiError);
    }
}
