import {i18n} from '@/plugins/i18n.js'


export default [
    {
        path: '/notifications',
        name: 'Notifications',
        meta: {
            title: i18n.t('Notifications | EKK'),
        },
        component: () => import('../../pages/notifications/Notifications'),
    }
]

