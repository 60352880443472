import axios from 'axios';
import router from "../router";

/** Creating the instance for axios */
const httpClient = axios.create({
    headers: {
        Accept: 'application/json'
    }
});
httpClient.defaults.withCredentials = true;

httpClient.interceptors.request.use(config => config, error => Promise.reject(error))

/** Adding the response interceptors */
httpClient.interceptors.response.use(
    response => response.data,
    error => {
        if (error.response.status === 401){
            router.push('/login')
        }
        throw error;
    }
);

export default httpClient;
