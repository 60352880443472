import {i18n} from '@/plugins/i18n.js'

export default [
    {
        path: '/logs-import',
        name: 'LogsImport',
        component: () => import('../../pages/logs/Logs'),
        meta: {
            title: i18n.t('Logs'),
        },
    },
    {
        path: '/logs-integrations',
        name: 'LogsIntegrations',
        component: () => import('../../pages/logs/Logs'),
        meta: {
            title: i18n.t('Logs'),
        },
    }
]
