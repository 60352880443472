import apiClient from "./ApiClient";
import handleApiError from "./handleApiError";

export default {
    getAnalytics(params, lang = 'ua') {
        return apiClient.post(`/api/v1/tickets/analytics`, params, {headers: {'X-localization': lang}}).then(resp => resp).catch(handleApiError);
    },
    getPublicAnalytics(params, lang = 'ua') {
        return apiClient.post(`/api/v1/tickets/analytics?public=1`, params, {headers: {'X-localization': lang}}).then(resp => resp).catch(handleApiError);
    },
    getDynamics(params, lang = 'ua') {
        return apiClient.get(`/api/v1/tickets/movies`, {params}).then(resp => resp).catch(handleApiError);
    },
}
