import apiClient from "./ApiClient";
import handleApiError from "./handleApiError";

export default {
    sendResetPassword(form, lang) {
        return apiClient.post('/api/v1/auth/reset-password', form, {headers: {'X-localization': lang}}).then(resp => resp)
            .catch(handleApiError);
    },
    sendLoginData(form, lang) {
        return apiClient.post('/api/v1/auth/login', form, {headers: {'X-localization': lang}}).then(resp => resp)
            .catch(handleApiError);
    },
    getIdentity() {
        return apiClient.get(`/api/v1/auth/user`).catch(handleApiError);
    },
    logout() {
        return apiClient.post(`/api/v1/auth/logout`).catch(handleApiError);
    },
    updateUserData(form){
        return apiClient.post('/api/v1/auth/user', form).then(resp => resp).catch(handleApiError);
    }
}
