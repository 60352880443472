export default {
    authSet({commit, state}, user) {
        if(Object.keys(user).length && user.id) {
            commit('authSet', user);
        }
    },
    authRemove({commit}) {
        commit('authRemove');
    }
}
