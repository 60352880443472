<template>
    <div class="error">
        <router-link :to="linkHome">
            <div class="logo">
                <img src="../../../src/assets/img/logo_EKK.svg" alt="logo">
                <div class="title">
                    {{$t('EKK')}}
                </div>
            </div>
        </router-link>
        <div class="error-main">
            <div class="error-title">{{ title }}</div>
            <div class="error-status-code">{{ code }}</div>
            <div class="error-description" v-html="message"></div>
            <router-link :to="linkHome" class="btn-home">{{$t('Home')}}</router-link>
        </div>
        <div class="error-image">
            <img src="../../assets/img/error.svg" alt="error-img">
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            linkHome: null,
        }
    },
    computed: {
        code() {
            return this.$route?.meta?.error?.code || 404;
        },
        message() {
            return this.$route?.meta?.error?.message || this.$t('404 message')
        },
        title() {
            return this.$route?.meta?.error?.title || this.$t('404 title')
        }
    },
    created() {
        if (this.role.getRole() === 1){
            this.linkHome = '/admin/users'
        } else if (this.role.getRole() === 2){
            this.linkHome = '/statistics'
        } else if (this.role.getRole() === 3){
            this.linkHome = '/home/distributors'
        } else if (this.role.getRole() === 4){
            this.linkHome = '/home/network'
        } else if (this.role.getRole() === 5){
            this.linkHome = '/home/cinema'
        }
    }
}
</script>

<style lang="scss" scoped>
body {
    background-color: #cad9f3;
    min-height: 90vh;
    position: relative;
}

.error {
    background-color: #cad9f3;
    min-height: 100vh;

    .logo {
        margin-left: 150px;
        padding-top: 30px;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
            margin-left: 50px;
        }

        .title {
            font-size: 20px;
            font-weight: 900;
            color: #0c0032;
            text-transform: uppercase;
            padding-left: 30px;
        }

        img {
            height: 67px;
            width: 67px;
        }
    }

    .error-main {
        margin-left: 150px;
        margin-top: 8%;
        @media (max-width: 1367px) {
            margin-top: 7vh;
        }
        @media (max-width: 768px) {
            margin-left: 50px;
        }

        .error-title {
            font-size: 30px;
            font-weight: 600;
            color: #0c0032;
        }

        .error-status-code {
            font-size: 200px;
            font-weight: 900;
            color: #0c0032;

            @media screen and (max-width: $sm) {
                font-size: 80px;
                margin-top: 40px;
            }
        }

        .error-description {
            font-size: 24px;
            color: #141213;
            max-width: 50vw;

            @media screen and (max-width: $sm) {
                max-width: 100%;
            }
        }

        .btn-home {
            width: 195px;
            height: 45px;
            border-radius: 2px;
            box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
            background-color: $color_darkblue;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 900;
            letter-spacing: 2px;
            margin-top: 50px;
            color: white;
            &:hover{
                box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
                color: $color_lightpink;
            }
        }
    }

    .error-image {
        position: absolute;
        bottom: 40px;
        right: 50px;

        img {
            width: 20vw;
        }
    }
}
</style>
