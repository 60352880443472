import apiClient from "./ApiClient";
import handleApiError from "./handleApiError";

export default {
    getSessions(params) {
        if (!params) {
            return null
        }
        return apiClient.get(`/api/v1/tickets/sessions`, {params}).then(resp => resp).catch(handleApiError);
    },
}
