import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
    state: () => {
        return {
            languages: ['ru', 'ua', 'en'],
            app_locale: 'ua'
        }
    },
    mutations,
    actions,
    getters
}
