import apiClient from "./ApiClient";
import handleApiError from "./handleApiError";

export default {
    getNetworkStatistics(format, dateFrom, dateTo, page, cityId) {
        return apiClient.get(`/api/v1/tickets/networks`,
            {params: {format: format, date_from: dateFrom, date_to: dateTo, page: page, region_id: cityId}})
            .then(resp => resp)
            .catch(handleApiError);
    },
    getSalesStatistics(type, cityId) {
        return apiClient.get(`/api/v1/tickets/sales`,
            {params: {type: type, region_id: cityId}})
            .then(resp => resp)
            .catch(handleApiError);
    },
    getDistributorsStatistic(cityId, page) {
        return apiClient.get(`/api/v1/tickets/distributors`,
            {params: {region_id: cityId, page: page}})
            .then(resp => resp)
            .catch(handleApiError);
    },
    getMoviesStatistics(format, dateFrom, dateTo, cityId, page) {
        return apiClient.get(`/api/v1/tickets/movies`,
            {params: {format: format, date_from: dateFrom, date_to: dateTo, region_id: cityId, page: page}})
            .then(resp => resp)
            .catch(handleApiError);
    },
    getMoviesStatForDistributor(format, dateFrom, dateTo, page){
        return apiClient.get(`/api/v1/tickets/movies`,
            {params: {format: format, date_from: dateFrom, date_to: dateTo, page: page}})
            .then(resp => resp)
            .catch(handleApiError);
    },
    sentImportStatistics(file, config) {
        return apiClient.post(`/api/v1/tickets/import`, file, config).then(resp => resp).catch(handleApiError);
    }
}
