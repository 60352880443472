import {i18n} from '@/plugins/i18n.js'


export default [
    {
        path: '/admin/documentation',
        name: 'Documentation',
        meta: {
            title: i18n.t('Documentation | EKK'),
            accessRole: ['SuperAdmin', 'NetworkAdministrator', 'CinemaEmployee'],
        },
        component: () => import('../../pages/documentation/Documentation'),
    }
]

