import {i18n} from '@/plugins/i18n.js'

export default [
    {
        path: '/analytics/cinema',
        name: 'CinemaAnalytics',
        component: () => import('../../pages/analytics/CinemaAnalytics'),
        meta: {
            title: i18n.t('Analytics | EKK'),
        },
    }
]
