export default {
    state: () => {
        return {
            notificationList: [],
            newAnalytic: false,
            allNotifications: [],
        }
    },
    getters: {
        getNotificationsList(state) {
            return state.notificationList
        },
        getNewAnalytic(state) {
            return state.newAnalytic
        },
        setNewAnalytic({commit}, v) {
            commit('setNewAnalytic', v)
        },
        getListAllNotifications(state){
            return state.allNotifications
        }
    },
    mutations: {
        updateList(state, notification){
            state.notificationList = notification
        },
        setNewAnalytic(state, v) {
            state.newAnalytic = v
        },
        updateListForAll(state, allNotification){
            state.allNotification = allNotification
        }
    },
    actions: {
        getNotifications(ctx) {
            let allNotification = JSON.parse(localStorage.getItem('notification'))
            if (!allNotification){
                allNotification = []
            }
            ctx.commit('updateList', allNotification)
        },
        getAllNotifications(ctx) {
            let allNotification = JSON.parse(localStorage.getItem('allNotifications'))
            if (!allNotification){
                allNotification = []
            }
            ctx.commit('updateListForAll', allNotification)
        }
    },

}
