import store from '../store/index'

export default {
    getRole(){
        let roleId = store.getters.identity.roles[0].pivot.role_id
        return roleId
    },
    getRoleName(){
        let roleName = store.getters.identity.roles[0].name
        return roleName
    }
}
