import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from "../router/routes/auth";
import admin from "../router/routes/admin";
import catalog from "@/router/routes/catalog.js"
import home from "../router/routes/home"
import profile from  "../router/routes/profile"
import analytics from "../router/routes/analytics"
import reports from "./routes/reports";
import notifications from './routes/notifications'
import store from "@/store"
import documentation from "./routes/documentation";
import logs from "./routes/logs";

import {i18n} from '@/plugins/i18n.js'
import PageNotFound from "@/pages/errors/Error";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import('../components/layouts/BaseLayout.vue'),
            children: [
                {
                    path: 'statistics',
                    name: 'Statistics',
                    component: () => import('@/pages/Statistics'),
                    meta: {
                        title: i18n.t('Statistics | EKK'),
                    },
                },
                {
                    path: 'dynamics',
                    name: 'Dynamics',
                    component: () => import('@/pages/Dynamics'),
                    meta: {
                        title: i18n.t('Dynamics | EKK'),
                    },
                },
                ...admin,
                ...catalog,
                ...home,
                ...profile,
                ...analytics,
                ...home,
                ...reports,
                ...notifications,
                ...documentation,
                ...logs,
            ]
        },
        ...auth,
        {path: "/403",
            component: () => import('../pages/errors/Error403'),
            name: 'Error403'
        },
        {
            path: '/widget',
            name: 'Widget',
            component: () => import('../components/widget/Widget'),
            meta: {
                title: 'Widget',
                notAuth: true
            }
        },
        {
            path: "*",
            component: PageNotFound,
            name: 'PageNotFound',
        },
    ]
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    if(!to.matched.some(record => !record.meta['notAuth']) || router.app.$store.getters.isLoggedIn) {
        if(to.meta.accessRole){

            let access = false;

            if(to.meta.accessRole instanceof Array) {
                access = to.meta.accessRole.find(role => store.getters.identity.roles.find(role1 => role1.name === role));
            }else{
                access = store.getters.identity.roles.find(role1 => role1.name === to.meta.accessRole);
            }
            if(!access) {
                router.push({ name: 'Error403' }).catch(() => {});
            }

        }

        next();
        return;
    }

    router.push({ name: 'login' }).catch(() => {});
})

export default router;
