import router from "../router";
import store from "../store";

export default err => {
    if (err.errors === null) throw null;

    err.errors = null;
    if('response' in err) {
        if(err.response != null) {
            if(err.response.status === 401 && (router.currentRoute.meta.noAuth || !Object.keys(router.currentRoute.meta).length)) {
                store.dispatch("authRemove");
            } else if(err.response.status === 422) {
                err.errors = err.response.data;
                err.errors['statusCode'] = err.response.status;
            } else if(err.response.status === 500) {
                err.errors = this.$t('Server error');
            }
            
        }
    }

    throw err.errors;
}
