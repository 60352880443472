import apiClient from "./ApiClient";
import handleApiError from "./handleApiError";

export default {
    getLogs(page, type, network_id, cinema_id, created_at) {
        return apiClient.get(`/api/v1/system/logs`,
            {params: {page: page, type: type, network_id: network_id, cinema_id: cinema_id, created_at: created_at}})
            .then(resp => resp)
            .catch(handleApiError);
    },
}
