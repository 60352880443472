export default {
    state: () => {
        return {
            appliedFilters: {
                format: 'day',
                date_from: new Date(Date.parse('Thu, 01 Jan 1970 00:00:00 GMT-0400')).toISOString().slice(0, 10),
                date_to: new Date(Date.parse('Thu, 01 Jan 1970 00:00:00 GMT-0400')).toISOString().slice(0, 10),
            },
            sortDynamicsByType: 'money'
        }
    },
    getters: {
        getAppliedDynamicsFilters(state) {
            return state.appliedFilters
        },
        getDynamicsSortByType(state) {
            return state.sortDynamicsByType
        }
    },
    mutations: {
        addDynamicsFilters(state, data) {
            state.appliedFilters = data
        },
        setDynamicsSortByType(state, data) {
            state.sortDynamicsByType = data
        }
    },
    actions: {
        addDynamicsFilters({commit}, p) {
            commit('addDynamicsFilters', p)
        }
    },
}