import {i18n} from '../../../plugins/i18n.js';

export default {
    setLocale({commit, state}, locale = null) {
        if(locale === null) {
            i18n.locale = state.app_locale;
            return;
        }

        let isInteger = Number.isInteger(locale);
        if((isInteger && !state.languages[locale]) || (!isInteger && !state.languages.includes(locale))) {
            console.error(`Locale ${locale} is not defined in:`, state.languages);
            return;
        }

        state.app_locale = isInteger ? state.languages[locale] : locale;
        i18n.locale = state.app_locale;
    }
}
