export default {
    state: () => {
        return {
            appliedFilters: {},
            sortByType: 'quantity'
        }
    },
    getters: {
        getAppliedFilters(state) {
            return state.appliedFilters
        },
        getSortByType(state) {
            return state.sortByType
        }
    },
    mutations: {
        addAllFilters(state, data) {
            state.appliedFilters = data
        },
        setSortByType(state, data) {
            state.sortByType = data
        }
    },
    actions: {
        addSidebarFilters({commit}, p) {
            commit('addAllFilters', p)
        }
    },
}