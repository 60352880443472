<template>
  <div id="app">
    <router-view></router-view>
    <div id="toast-container" class="toast-container position-absolute top-0 end-0 p-3" style="z-index: 10000"></div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
  @import "@/assets/scss/_toasts.scss";
</style>
