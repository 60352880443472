import {i18n} from '@/plugins/i18n.js'

export default [
    {
        path: '/home/cinema',
        component: () => import('../../pages/home/StatisticsCinema'),
        name: 'StatisticsCinema',
        meta: {
            title: i18n.t('Sales statistics'),
            accessRole: ['SuperAdmin', 'CinemaEmployee'],
        },
    },
    {
        path: '/home/network',
        component: () => import('../../pages/home/StatisticsNetwork'),
        name: 'StatisticsNetwork',
        meta: {
            title: i18n.t('Sales statistics'),
            accessRole: ['SuperAdmin', 'NetworkAdministrator'],
        },
    },
    {
        path: '/home/distributors',
        component: () => import('../../pages/home/StatisticsDistributors'),
        name: 'StatisticsDistributors',
        meta: {
            title: i18n.t('Sales statistics'),
            accessRole: ['SuperAdmin', 'Distributor'],
        },
    }

]
