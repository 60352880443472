import router from "../index";
import {i18n} from '@/plugins/i18n.js'

let authCatch = (to, from, next) => {
    if(router.app.$store.getters.isLoggedIn) {
        next('/');
    }
    next();
}

export default [
    {
        path: '/login',
        name: 'login',
        meta: {
            title: i18n.t('Authorization | EKK'),
            notAuth: true
        },
        beforeEnter: authCatch,
        component: () => import('../../pages/auth/Login')
    }, {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            title: i18n.t('Remind password | EKK'),
            notAuth: true
        },
        beforeEnter: authCatch,
        component: () => import('../../pages/auth/Login')
    }
]
