import apiClient from "./ApiClient";
import handleApiError from "./handleApiError";

export default {
    getUsers(params) {
        return apiClient.get(`/api/v1/users`, {params}).then(resp => resp).catch(handleApiError);
    },
    getUserData() {
        return apiClient.get(`/api/v1/auth/user`).catch(handleApiError);
    },
    getRoles() {
        return apiClient.get(`/api/v1/access/roles`).then(resp => resp).catch(handleApiError);
    }
}
