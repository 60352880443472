import {i18n} from '@/plugins/i18n.js'


export default [
    {
        path: '/profile',
        name: 'Profile',
        meta: {
            title: i18n.t('Profile'),
        },
        component: () => import('../../pages/Profile/Profile'),
    }
]

