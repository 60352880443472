export default {
    'someKey': 'укр',
    'Create' : 'Створити',
    'Movies' : 'Фільми',
    'Add movie' : 'Додати фільм',
    'Movie updated successfully' : 'Фільм успішно оновлено',
    'Movie successfully added' : 'Фільм успішно додано',
    'Add a movie' : 'Додати фільм',
    'Edit movie' : 'Редагувати фільм',
    'Add a network' : 'Додати мережу',
    'Edit network' : 'Редагувати мережу',
    'Company' : 'Компанія',
    'Network successfully added' : 'Мережу успішно додано',
    'Network successfully edited' : 'Мережу успішно відредаговано',
    'Networks' : 'Мережі',
    'The page you are looking for is unavailable. <br>Perhaps the page has been deleted or address was incorrect': 'Сторінка, яку Ви шукаєте, не знайдена. <br>Можливо, сторінку було видалено, або введено некоректну адресу',
    'Companies' : 'Компанії',
    'Save' : 'Зберегти',
    'Cancel' : 'Скасувати',
    'Legal entity' : 'Юридична особа',
    'Individual' : 'Фізична особа',
    'All' : 'Усі',
    'Name' : 'Назва',
    'Name ' : 'Ім\'я',
    'Network' : 'Мережа',
    'Cinema' : 'Кінотеатр',
    'Type' : 'Тип',
    'Directory' : 'Довідники',
    'Directory of legal entities' : 'Довідник юросіб',
    'Latter' : 'Остання',
    'First' : 'Перша',
    'Companies | EKK' : 'Компанії | Єдиний Кіно Квиток',
    'Network | EKK' : 'Мережі | Єдиний Кіно Квиток',
    'Movies | EKK' : 'Фільми | Єдиний Кіно Квиток',
    'Authorization | EKK' : 'Авторизація | Єдиний Кіно Квиток',
    'Remind password | EKK' : 'Нагадати пароль | Єдиний Кіно Квиток',
    'Analytics' : 'Аналітика',
    'In cinemas' : 'По кінотеатрам',
    'Distributors' : 'Дистриб\'ютори',
    'Halls' : 'Зали',
    'Sessions' : 'Сеанси',
    'Date' : 'Дата',
    'City': 'Мiсто',
    'Statistics' : 'Статистика',
    'Cinema sales' : 'Продажі по кінотеатрам',
    'Statistics on cinemas' : 'Статистика по кінотеатрам',
    'Statistics on cinemas/networks' : 'Статистика по кінотеатрам/мережам',
    'Profile' : 'Особистий кабінет',
    'login' : 'Логiн',
    'Language' : 'Мова',
    'Log out of the account' : 'Вийти з акаунту',
    'Network of cinemas' : 'Мережа кінотеатрів',
    'Network cinemas' : 'Кінотеатри мережі',
    'Making changes to personal data' : 'Внесення змін до особистих даних',
    'Phone' : 'Телефон',
    'Data saved successfully' : 'Дані успішно збережено',
    'Click on the box to select cinemas': 'Натисніть на поле для вибору кінотеатрів',
    'Click on the box to select distributors': 'Натисніть на поле для вибору дистриб\'юторiв',
    'Click on the box to select movies': 'Натисніть на поле для вибору фiльмiв',
    'Click on the box to select networks': 'Натисніть на поле для вибору мережi',
    'Click on the box to select halls': 'Натисніть на поле для вибору залiв',
    'Click on the box to select sessions': 'Натисніть на поле для вибору сеансiв',
    'Home page' : 'Домашня сторінка',
    'Sales statistics' : 'Статистика продажів',
    'Warning': 'Попередження',
    'Warning Text': 'Ви дiйсно хочете змiнити пароль?',
    'Clear': 'Очистити',
    'Apply': 'Застосувати',
    'Distributor statistics' : 'Статистика дистриб\'юторів',
    'Movie sales' : 'Продажі фільмів',
    'Yesterday' : 'Вчора',
    'This week' : 'Цей тиждень',
    'This month' : 'Цей місяць',
    'Last week' : 'Минулий тиждень',
    'Last month' : 'Минулий місяць',
    'Movie' : 'Фільм',
    'Movies ' : 'Фільмів',
    'Tickets' : 'Квитків',
    'Sum' : 'Сума',
    'Period' : 'Період',
    'File uploaded successfully' : 'Файл успішно завантажено',
    'You can import your cinema sales data using the form below:' : 'Ви можете імпортувати дані продажів Вашого кінотеатру, скориставшись формою нижче:',
    'Import data' : 'Імпорт даних',
    'Sales file' : 'Файл продажів',
    'Select a file' : 'Оберіть файл',
    'Import' : 'Імпорт',
    'Only xlsx and csv files are supported' : 'Підтримуються лише файли формату xlsx та csv',
    'File too large' : 'Занадто великий файл',
    'Cashier' : 'Каси',
    'Online' : 'Онлайн',
    '404 message': 'Сторінка, яку Ви шукаєте, не знайдена. Можливо, сторінку було видалено, або введено некоректну адресу',
    '404 title': 'Помилка',
    'Access denied' : 'В доступі відмовлено',
    'Error' : 'Помилка',
    'File in processing' : 'Файл в обробці',
    'Notification' : 'Сповіщення',
    'To all notifications' : 'До усіх сповіщень',
    'Read all' : 'Прочитати усі',
    'Reports' : 'Звіти',
    'Results for distributors' : 'Підсумки по дистриб\'юторам',
    'Results for cinemas' : 'Підсумки по кінотеатрам',
    'Results for networks' : 'Підсумки по мережам',
    'Distributor' : 'Дистриб\'ютор',
    'Generate a report' : 'Сформувати звіт',
    'No elements found': 'Элементiв списку не знайдено',
    'Server error': 'Помилка сервера',
    'Please upload a valid file' : 'Будь ласка, завантажте валідний файл',
    'Previous' : 'Попередній',
    'Next' : 'Наступний',
    'Today' : 'Сьогодні',
    'The report was successfully generated' : 'Звіт успішно сформовано',
    'Download excel' : 'Завантажити excel',
    'The result of one movie theater' : 'Підсумок одного кінотеатра за фільмами',
    'One distributor report for one movie' : 'Звіт одного дистриб\'ютора за одним фільмом',
    'Report of one cinema for one film' : 'Звіт одного кінотеатра за одним фільмом',
    'Notifications' : 'Сповіщення',
    'Notifications | EKK' : 'Сповіщення | Єдиний Кіно Квиток',
    'Start date of sale' : 'Початкова дата продажу',
    'End date of sale' : 'Кінцева дата продажу',
    'Session start date' : 'Початкова дата сеансу',
    'Session end date' : 'Кінцева дата сеансу',
    'Admin panel' : 'Адмін-панель',
    'Users' : 'Користувачі',
    'Dynamics' : 'Динаміка',
    'Cinemas' : 'Кінотеатри',
    'The notification list is empty' : 'Список сповіщень порожній',
    'Roles' : 'Ролі',
    'Token' : 'Токен',
    'Documentation' : 'Документація',
    'Documentation | EKK' : 'Документація | Єдиний Кіно Квиток',
    'Продажі фільмів' : 'Movie sales',
    'Logs' : 'Логи',
    'Event logs' : 'Логи подій',
    'Download report' : 'Завантажити звіт',
    'Name errors' : 'Назва помилки',
    'Home' : 'На головну',
    'Foreign' : 'Іноземний',
    'Ukrainian' : 'Український',
    'Type of production' : 'Тип виробництва',
    'save_button': 'Зберегти',
    'add_button': 'Додати',
    'yes_button': 'Так',
    'no_button': 'Нi',
    'password': 'Пароль',
    'email': 'E-mail',
    'name': 'Ім’я',
    'phone': 'Телефон',
    'Phone Error' : 'Невiрний формат телефону',
    'role': 'Роль',
    'cinema': 'Кінотеатри',
    'cinemas_select': 'Вибрати кінотеатри',
    'checkmark_active_user': 'Активний',
    'checkmark_noactive_user': 'Не активний',
    'home': 'Головна',
    'empty' : 'Результатiв не знайдено',
    'title': 'Користувачi',
    'add': 'Додати',
    'cancel': 'Скасувати',
    'add_user': 'Додати користувача',
    'edit_user': 'Редагувати користувача',
    'edit': 'Зберегти',
    'Ekk': 'Користувачi | Єдиний Кіно Квиток',
    'ID': 'ID',
    'Email': 'Email',
    'Role': 'Роль',
    'User added': 'Користувача додано',
    'Information successfully updated': 'Iнформацiю успiшно оновлено',
    'State registration number' : 'Державний реєстраційний номер',
    'Movie title' : 'Назва фільму',
    'Production' : 'Виробництво',
    'Scriptwriter' : 'Автор сценарію',
    'Relevant rights to the film' : 'Відповідні права на фільм',
    'On the territory of Ukraine belong' : 'На території України належать',
    'Validity of rights' : 'Термін дії прав',
    'Decision of the expert commission' : 'Рішення експертної комісії',
    'Description of the film' : 'Опис фільму',
    'Translation' : 'Переклад',
    'Director-producer' : 'Режисер-постановник',
    'Owned (transferred)' : 'Належать (передані)',
    'The method of exercising the rights to the film' : 'Спосіб реалізації прав на фільм',
    'Index of conditions of distribution and demonstration' : 'Індекс умов розповсюдження і демонстрування',
    'add_new': 'Додати дистриб’ютора',
    'catalog_index': 'Довiдники',
    'edit_title': 'Редагувати дистриб’ютора',
    'company': 'Компанiя',
    'Distributor added': 'Дистриб\`ютора додано',
    'Availability of halls': 'Наявнiсть залiв',
    'add_hall': 'Додати зали',
    'id': 'ID',
    'network': 'Мережа',
    'city': 'Мiсто',
    'status': 'Статус',
    'Status': 'Статус',
    'Api name': 'Назва з API',
    'Api id': 'ID з API',
    'Is active': 'Активний',
    'No active': 'Не активний',
    'Number seats': 'Кількість місць',
    'Cinema added': 'Кінотеатр додано',
    'Add a company': 'Додати компанію',
    'Legal address' : 'Юридична адреса',
    'Actual address' : 'Фактична адреса',
    'Bank' : 'Банк',
    'MFIs' : 'МФО',
    'TIN' : 'ІПН',
    "USREOU" : "ЄДРПОУ",
    'Certificate of VAT' : 'Свідоцтво платника ПДВ',
    'Editing companies': 'Редагування компанії',
    'Company successfully added' : 'Компанія успішно додана',
    'The company has been successfully updated' : 'Компанія успішно оновлена',
    'Statistics | EKK': 'Статистика | Єдиний Кіно Квиток',
    'Display': 'Відобразити',
    'Day': 'день',
    'Week': 'тиждень',
    'Month': 'мiсяць',
    'Count': 'кількість',
    'Amount': 'гривнi',
    'Empty data': 'Жодних даних не знайдено',
    'Filters': 'Фільтри',
    'Analytics | EKK': 'Аналiтика | Єдиний Кіно Квиток',
    'Dynamics | EKK': 'Динаміка | Єдиний Кіно Квиток',
    'Film': 'Фiльм',
    'Compare With': 'Порiвняти з',
    'Choose movies to compare': 'Виберiть фiльми для порiвняння',
    'Edit' : 'Редагувати',
    'Show details' : 'Показати деталі',
    'Download' : 'Завантажити',
    'Remind password': 'Нагадати пароль',
    'Login': 'Увійти',
    'Remember me': 'Запам\'ятати мене',
    'Authorization': 'Авторизація',
    'Password': 'Пароль',
    'Username': 'Логін',
    'Add user' : 'Додати користувача',
    'Edit user' : 'Редагувати користувача',
    'Add a movie theater' : 'Додати кінотеатр',
    'Edit movie theater' : 'Редагувати кінотеатр',
    'Edit halls' : 'Редагувати зали',
    'Add halls' : 'Додати зали',
    'Number of seats' : 'Кількість місць',
    'Add a distributor' : 'Додати дистриб’ютора',
    'Edit Distributor' : 'Редагувати дистриб’ютора',
    'EKK' : 'Єдиний Кіно Квиток',
    'Download template' : 'Завантажити шаблон',
    'Spectators' : 'Глядачi',
    'Ticket price' : 'Вартiсть квитка',
    'Tickets sold' : 'Продано квитків',
    'Sales' : 'Об’єм продажів',
    'Efficiency' : 'Ефективність',
    'Number of cinemas' : 'Кількість кінотеатрів',
    'Number of movies' : 'Кількість фільмів',
    'Number of sessions' : 'Кількість сеансів',
    'No data found' : 'Жодних даних не знайдено',
    'January' : 'Сiчень',
    'February' : 'Лютий',
    'March' : 'Березень',
    'April' : 'Квiтень',
    'May' : 'Травень',
    'June' : 'Червень',
    'July' : 'Липень',
    'August' : 'Серпень',
    'September' : 'Вересень',
    'October' : 'Жовтень',
    'November' : 'Листопад',
    'December' : 'Грудень',
    'Sunday' : 'Неділя',
    'Monday' : 'Понеділок',
    'Tuesday' : 'Вівторок',
    'Wednesday' : 'Середа',
    'Thursday' : 'Четвер',
    'Friday' : 'Пятниця',
    'Saturday' : 'Субота',
    'Sun' : 'Нд',
    'Mon' : 'Пн',
    'Tue' : 'Вт',
    'Wed' : 'Ср',
    'Thu' : 'Чт',
    'Fri' : 'Пт',
    'Sat' : 'Сб',
    'Select date' : 'Виберіть дату',
    'Select date range' : 'Виберіть діапазон дат',
    'Delete all': 'Видалити всi',
    'A password confirmation email has been sent to your email address.' : 'На Вашу електрону адресу було надіслано листа для підтвердження зміни пароля.',
    'If it wasn\'t you, ignore this letter' : 'Якщо це були не Ви - проігноруйте даний лист',
    'Sorry, nothing found' : 'Вибачте, нічого не знайдено',
    'Do you really want to close the form?' : 'Ви дiйсно хочете закрити форму?',
    'Yes' : 'Так',
    'No' : 'Ні',
    'Number' : 'Номер',
    'Distributors | EKK' : 'Дистриб’ютори | Єдиний Кіно Квиток',
    'Cinemas | EKK' : 'Кінотеатри | Єдиний Кіно Квиток',
    'ADD' : 'Додати',
    'Add' : 'Додати',
    'Users | EKK' : 'Користувачі | Єдиний Кіно Квиток',
    'Cinema successfully removed' : 'Кінотеатр успішно видалено',
    'Do you want to delete the' : 'Бажаєте видалити',
    'cinema ' : 'кінотеатр',
    'Delete item ?' : 'Видалити елемент ?',
    common: {
        form: {
    //         'save_button': 'Зберегти',
    //         'add_button': 'Додати',
    //         'yes_button': 'Так',
    //         'no_button': 'Нi',
    //         'login' : 'Логiн',
    //         'password': 'Пароль',
    //         'email': 'E-mail',
    //         'name': 'Ім’я',
    //         'phone': 'Телефон',
    //         'Phone Error' : 'Невiрний формат телефону',
    //         'role': 'Роль',
    //         'cinemas_network': 'Мережа кінотеатрів',
    //         'cinema': 'Кінотеатри',
    //         'cinemas_select': 'Вибрати кінотеатри',
    //         'checkmark_active_user': 'Активний',
    //         'checkmark_noactive_user': 'Не активний',
    //         'multiselect_cancel': 'Видалити всi'
        },
    },

    // components: {
    //     breads: {
    //         'home': 'Головна'
    //     },
    //     ui: {
    //         tables: {
    //             tableMain: {
    //                 'empty' : 'Результатiв не знайдено'
    //             }
    //         },
    //     },
    //     companiesTable: {
    //         'Name': 'Назва',
    //         'Network': 'Мережа',
    //         'Сinema': 'Кінотеатр',
    //         'Type': 'Тип'
    //     }
    // },

    pages: {
        // admin: {
        //     users: {
        //         'title': 'Користувачi',
        //         'add': 'Додати',
        //         'Admin panel': 'Адмiн-панель',
        //         'cancel': 'Скасувати',
        //         'add_user': 'Додати користувача',
        //         'edit_user': 'Редагувати користувача',
        //         'edit': 'Зберегти',
        //         'Ekk': 'Користувачi | Єдиний Кіно Квиток',
        //         'table_colums' : {
        //             'ID': 'ID',
        //             'Email': 'Email',
        //             'Name': 'Iм’я',
        //             'Role': 'Роль'
        //         },
        //         'User added': 'Користувача додано',
        //         'Information successfully updated': 'Iнформацiю успiшно оновлено'
        //     }
        // },
        // films: {
        //
        //     'State registration number' : 'Державний реєстраційний номер',
        //     'Movie title' : 'Назва фільму',
        //     'Production' : 'Виробництво',
        //     'Scriptwriter' : 'Автор сценарію',
        //     'Relevant rights to the film' : 'Відповідні права на фільм',
        //     'On the territory of Ukraine belong' : 'На території України належать',
        //     'Validity of rights' : 'Термін дії прав',
        //     'Decision of the expert commission' : 'Рішення експертної комісії',
        //     'Description of the film' : 'Опис фільму',
        //     'Translation' : 'Переклад',
        //     'Director-producer' : 'Режисер-постановник',
        //     'Owned (transferred)' : 'Належать (передані)',
        //     'The method of exercising the rights to the film' : 'Спосіб реалізації прав на фільм',
        //     'Index of conditions of distribution and demonstration' : 'Індекс умов розповсюдження і демонстрування',
        //     'Date' : 'Дата',
        //
        // },

        catalog: {
            // distributors: {
            //     'Ekk': 'Дистриб’ютори | Єдиний Кіно Квиток',
            //     'title': 'Дистриб’ютори',
            //     'add_new': 'Додати дистриб’ютора',
            //     'catalog_index': 'Довiдники',
            //     'edit_title': 'Редагувати дистриб’ютора',
            //     'edit': 'Зберегти',
            //     'cancel': 'Скасувати',
            //     'add': 'Додати',
            //     'name': 'Назва',
            //     'company': 'Компанiя',
            //     'ID': 'ID',
            //     'table_colums' : {
            //         'id': 'ID',
            //         'name': 'Назва',
            //         'company': 'Компанiя'
            //     },
            //     'Distributor added': 'Дистриб\`ютора додано',
            //     'Information successfully updated': 'Iнформацiю успiшно оновлено'
            // },

            cinemas: {
                // 'Ekk': 'Кінотеатри | Єдиний Кіно Квиток',
                // 'title': 'Кінотеатри',
                // 'add_new': 'Додати кінотеатр',
                // 'catalog_index': 'Довiдники',
                // 'edit_title': 'Редагувати кінотеатр',
                // 'edit': 'Зберегти',
                // 'cancel': 'Скасувати',
                // 'add': 'Додати',
                // 'edit_hall': 'Редагувати зали',
                // 'Availability of halls': 'Наявнiсть залiв',
                // 'add_hall': 'Додати зали',
                // 'table_colums': {
                //     'id': 'ID',
                //     'name': 'Назва',
                //     'network': 'Мережа',
                //     'company': 'Компанiя',
                //     'city': 'Мiсто',
                //     'status': 'Статус'
                // },
                // 'form' : {
                //     'Name': 'Назва',
                //     'ID': 'ID',
                //     'Company': 'Компанiя',
                //     'City': 'Мiсто',
                //     'Api name': 'Назва з API',
                //     'Api id': 'ID з API',
                //     'Network': 'Мережа',
                //     'Is active': 'Активний',
                //     'No active': 'Не активний',
                //     'Number seats': 'Кількість місць'
                // },
                // 'Cinemas added': 'Кінотеатр додано',
                // 'Information successfully updated': 'Iнформацiю успiшно оновлено'
            }
        },

        companies: {
            // 'Add a company': 'Додати компанію',
            // 'Name' : 'Назва',
            // 'Type' : 'Тип',
            // 'Legal address' : 'Юридична адреса',
            // 'Actual address' : 'Фактична адреса',
            // 'Bank' : 'Банк',
            // 'MFIs' : 'МФО',
            // 'TIN' : 'ІПН',
            // "USREOU" : "ЄДРПОУ",
            // 'Certificate of VAT' : 'Свідоцтво платника ПДВ',
            // 'Phone' : 'Телефон',
            // 'Editing companies': 'Редагування компанії',
            // 'Company successfully added' : 'Компанія успішно додана',
            // 'The company has been successfully updated' : 'Компанія успішно оновлена'
        },

        statistics: {
            // 'Title': 'Статистика',
            // 'Statistics | EKK': 'Статистика | Єдиний Кіно Квиток',
        },

        analytics: {
            // 'Display': 'Відобразити',
            // 'Day': 'день',
            // 'Week': 'тиждень',
            // 'Month': 'мiсяць',
            // 'Count': 'кількість',
            // 'Amount': 'гривнi',
            // 'Empty data': 'Жодних даних не знайдено',
            // 'Filters': 'Фільтри',
            // 'Analytics | EKK': 'Аналiтика | Єдиний Кіно Квиток',
        },

        dynamics: {
            // 'Dynamics | EKK': 'Динаміка | Єдиний Кіно Квиток',
            // 'title': 'Динаміка',
            // 'Count': 'глядачi',
            // 'Amount': 'вартiсть квитка',
            // 'Film': 'Фiльм',
            // 'Compare With': 'Порiвняти з',
            // 'Empty data': 'Жодних даних не знайдено',
            // 'Choose movies to compare': 'Виберiть фiльми для порiвняння'
        }
    },
    // 'Remind password': 'Нагадати пароль',
    // 'Login': 'Увійти',
    // 'Remember me': 'Запам\'ятати мене',
    // 'Authorization': 'Авторизація',
    // 'Password': 'Пароль',
    // 'Username': 'Логін',
}
