import Vue from 'vue'

export default function createToast(type, title, message, icon) {
  const Toast = {
    template: `
    <div class="toast" :class="'toast--'+type" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <div class="toast-header__iconWrapper">
            <span class="material-icons-outlined">{{ icon || 'info' }}</span>
        </div>

        <strong class="toast-header__title me-auto">{{ title }}</strong>
        <a class="toast-header__close" data-bs-dismiss="toast"><i class="bi bi-x-circle-fill"></i></a>
      </div>
      <div v-if="message" class="toast-body">{{ message }}</div>
    </div>`,
    props: ['type', 'title', 'message', 'icon']
  };

  const ToastEl = Vue.extend(Toast);
  const t = new ToastEl({
    propsData: {
      message, title, type, icon
    }
  }).$mount();
  document.getElementById('toast-container').appendChild(t.$el)

  let newObjToast = new bootstrap.Toast(t.$el, {})
  newObjToast.show()
}